const pending = state => state.pending;
const authenticated = state => state.authenticated;
const username = state => state.data.username;
const name = state => state.data.name;
const lastname = state => state.data.lastname;
const avatar = state => state.avatar;
const user = state => state.data;
const uniqid = state => state.data.uniqid;
const recoverSending = state => state.recover.sending;
const recoverSuccess = state => state.recover.success;
const recoverErrors = state => state.recover.errors;

const changePasswordSuccess = state => state.changePassword.success;
const changePasswordSending = state => state.changePassword.sending;
const changePasswordErrors = state => state.changePassword.errors

const hashValidateSending = state => state.hashValidate.sending;
const hashValidateSuccess = state => state.hashValidate.success;
const hashValidateErrors  = state => state.hashValidate.errors;

export default {
  pending,
  authenticated,
  user,
  username,
  name,
  lastname,
  avatar,
  uniqid,
  recoverSending,
  recoverSuccess,
  recoverErrors,
  changePasswordSending,
  changePasswordSuccess,
  changePasswordErrors,
  hashValidateSending,
  hashValidateSuccess,
  hashValidateErrors,
};
