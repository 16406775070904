<template>
  <div id="app">
    <v-app>
      <NoConnection />
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
  import NoConnection from '@/components/ui/connection/NoConnection'
  import LightTheme from '@/plugins/vuetify/theme/light.js'
  import {mapGetters} from "vuex";

  export default {
    name: 'App',
    components:{
      NoConnection,
    },
    computed:{
      ...mapGetters({
        authenticated: 'services/api/auth/authenticated'
      }),
    },
    created(){
      let me = this;
       me.$vuetify.theme.themes.light.primary = LightTheme.primary;
    },
    mounted(){
      let me = this,
        preloader = document.querySelector('#app--preloader');

      /**
       * Remove the application preloader, this preloader lays in the index.html page and has no relation
       * with the application at all.
       */
      if(preloader){
        preloader.remove();
      }

      /**
       * Init browser window events (Checks for viewport resize / detects mobile client)
       */
      me.$store.dispatch('services/browser/window/init');

    },
    watch:{
      authenticated(n){
        if (!n) return;
        // Obtain server date and start timer to have an accurate client date
        this.$store.dispatch('services/api/server/date/fetch');
        // Keep the session alive
        this.$store.dispatch('AuthFirebase/keepAlive', {interval: 30});
      }
    }
  }
</script>
