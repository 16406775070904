import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetchUnread = () => {
  return Api.get(endpoints.get('fetchUnread'));
}

const fetch = (page=1) => {
  let params = new URLSearchParams();
  params.append('page', page)
  return Api.get(endpoints.get('fetch')+'?'+params.toString());
}

const remove = (args) => {
  let params = new URLSearchParams();
  params.append('notification', args.id);

  return Api.post(endpoints.get('delete'), params,{
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

const read = args => {
  let params = new URLSearchParams();
  params.append('notification', args.id);

  return Api.post(endpoints.get('read'), params,{
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

}

export default {
  fetchUnread,
  fetch,
  remove,
  read
};
