import Store from '@/store';

const LEGACY_LOGIN = {
  name: 'LegacyLogin',
  path: '/legacy-login',
  component: () => import(/* webpackChunkName: "Login" */ '@/views/auth/Login'),
  meta: {
    requiresAuth: false,
    history: false,
  },
};

const LOGIN = {
  name: 'Login',
  path: '/login',
  component: () => import(/* webpackChunkName: "Login" */ '@/pages/Login'),
  meta: {
    requiresAuth: false,
    history: false,
  },
};

const AUTH = {
  name: 'Auth',
  path: '/auth-return',
  component: () => import(/* webpackChunkName: "Auth" */ '@/pages/Auth'),
  meta: {
    requiresAuth: false,
  },
};

const LOGOUT = {
  name: 'Logout',
  path: '/logout',
  beforeEnter: (to, from, next) => {
    const logoutFirebase = Store.dispatch('AuthFirebase/signOut');
    const logoutAuth = Store.dispatch('services/api/auth/logout');

    Promise.all([logoutFirebase, logoutAuth])
      .then(() => next({ name: 'Login' }));
  },
  meta: {
    requiresAuth: true,
  },
};

const LOST_PASSWORD = {
  name:'lostPassword',
  path:'/optin/password/recover',
  component: () => import('@/views/auth/LostPassword'),
  localizedName: null, //@TODO add localized named for logout
  meta:{
    isHomePage: false,
    requiresAuth: false,
    roles:[]
  }
}

const CHANGE_PASSWORD = {
  name:'changePassword',
  path:'/optin/password/change/:hash',
  props: true,
  component: () => import('@/views/auth/ChangePassword'),
  localizedName: null, //@TODO add localized named for logout
  meta:{
    isHomePage: false,
    requiresAuth: false,
    roles:[]
  }
}

const DASHBOARD = {
  name: 'dashboard',
  path: '/dashboard',
  alias: ['/'],
  component: () => import('@/views/Dashboard'),
  localizedName: null,
  meta:{
    isHomePage: true,
    requiresAuth: true,
    history: true,
    roles: []
  }
}

const COURSE = {
  name: 'course',
  path: '/course/calendar/:course/:calendar?',
  props: true,
  component: () => import('@/views/course/Calendar'),
  localizedName: null,
  meta:{
    isHomePage: false,
    requiresAuth: true,
    history: true,
    roles:[],
  }
}

export default [
  LEGACY_LOGIN,
  LOGIN,
  AUTH,
  LOGOUT,
  LOST_PASSWORD,
  CHANGE_PASSWORD,
  DASHBOARD,
  COURSE
]
