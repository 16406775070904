import initialState from './state.js'

const FETCH_UPDATED = (state, data) => {
  state.values = data;
};

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.fetching = state.fetching;
  Object.assign(state, initialState);
}

const FETCHING = (state, value) => {
  state.fetching = !!value;
}

const ERROR = (state, error) => {
  state.error = error;
}

export default {
  FETCH_UPDATED,
  FETCHING,
  RESET_STATE,
  ERROR
}
