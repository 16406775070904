export default {
  get: function(){
    return {
      values: [],
      fetching: null,
      error: null,
      success: null,
    }
  }
}
