import myCache from './cache';
import storeCache from '@/services/api/cache'
import _  from 'underscore'

export default {
  /**
   * Gets available list of languages
   * @todo add browser caching logic from localStorage
   */
  getList(state){
    return state.api.get.values;
  },
  getDefault: function (state) {
    for (let language of state.api.get.values) {
      if (true === language.default) {
        return language.iso
      }
    }
  },
  getCurrent(state, _this){
    return state.current ? state.current : _this.getDefault;
  },
  getActive(state, _this){
    let languages = _this.getList;
    return languages.filter((lang) => {
      return true === lang.active;
    });
  },
  getCurrentFromCache(state){
    let cache = storeCache.get(myCache.STORAGE_KEY);
    return _.has(cache, 'current') ? cache.current : null;
  }
}
