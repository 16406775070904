import initialState from './state.js'

const FETCH_UPDATED = (state, values) => {
  let calendarId = parseInt(values.args.calendar);

  state.course = values.data.course;
  state.list = values.data.calendars;

  return SET_CURRENT_CALENDAR(state, calendarId);
}

const SET_CURRENT_CALENDAR = (state, calendarId=null) => {
  let calendars = state.list,
  current = null;

  calendarId = parseInt(calendarId);

  //Find the calendar for the current course
  for(let i = 0; i < calendars.length; i++){

    calendars[i].current = false;

    if(calendarId === calendars[i].id){
      current = calendars[i];
      calendars[i].current = true;
    }

  }
  /**
   * Assume a default calendar
   */
  if(null === current && calendars.length > 0){
    calendars[0].current = true;
    current = calendars[0];
  }

  state.list = calendars;
  state.current = {...state.current, ...current};
}

const ERROR = (state, error) => {
  state.error = error
}

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.fetching = state.fetching;
  Object.assign(state, iState);
}

const FETCHING = (state, value) => {
  state.fetching = !!value;
}

export default {
  FETCH_UPDATED,
  FETCHING,
  RESET_STATE,
  SET_CURRENT_CALENDAR,
  ERROR
};
