import courseStore from '@/services/api/course/course'
import unifiedCoursesStore from '@/services/api/course/unified'
import courseCalendarStore from '@/services/api/course/calendar'
import coordinatorStore from '@/services/api/course/coordinator'

export default {
	namespaced:true,
	modules:{
    course: courseStore,
    unified: unifiedCoursesStore,
    calendar: courseCalendarStore,
    coordinator: coordinatorStore
  }
}
