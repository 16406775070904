const list = state => state.values;
const fetching = state => state.fetching;
const error = state => state.error;
const success = state => state.success;

const findTeacher = state => uniqid => {
  let i = 0;

  for(i; i < state.values.length; i++){
    let teacher = state.values[i];

    if(uniqid !== teacher.uniqid){
      continue;
    }

    break;
  }

  return state.values[i];
}

export default {
  list,
  fetching,
  error,
  success,
  findTeacher
};
