import apiService from '@/services/api/api/store'
import authenticationService from '@/services/api/auth/store'
import courseService from '@/services/api/course'
import languageService from '@/services/api/language/store'
import notificationService from '@/services/api/notification'
import router from '@/services/api/router'
import translationService from '@/services/api/translation/store'
import serverService from '@/services/api/server'
import bootcampService from '@/services/api/bootcamp'

export default{
  namespaced: true,
  modules: {
    api: apiService,
    auth: authenticationService,
    course: courseService,
    language: languageService,
    notification: notificationService,
    router: router,
    translation: translationService,
    server: serverService,
    bootcamp: bootcampService
  }
}
