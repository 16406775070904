export default {
  get: function () {
    return {
      pending: false,
      authenticated: false,
      data:{},
      avatar: null,
      session: null,
      logout:{
        success: null,
        error: null
      },
      recover:{
        success: null,
        sending: null,
        errors: {
          captcha: null,
          optin: null
        }
      },
      hashValidate:{
        success: null,
        sending: null,
        errors: {
          expired: null,
        }
      },
      changePassword:{
        success: null,
        sending: null,
        errors: {
          invalid: null
        }
      }
    }
  }
}
