import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();

  params.append('course_code', args.course);
  params.append('calendar', args.calendar);

  if(null !== args.user){
    params.append('user', args.user);
  }

  return Api.get(endpoints.get('stats')+'?'+params.toString());
}

export default {
  fetch,
};
