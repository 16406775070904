import Vue from 'vue'
import VueRouter from 'vue-router'
import Routes from './routes'
import Store from '@/store'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: Array.from(Routes.values())
});

router.beforeEach(async (to, from, next) => {
  if(to.name === 'Auth') return next();

  try {
    const isAuthenticated = await Store.dispatch('services/api/auth/isAuthenticated');

    if (from.query.redir && !from.query.redir.startsWith('/')) {
      from.query.redir = `/${from.query.redir}`;
    }

    if (isAuthenticated && from.path === '/legacy-login' && from.query.redir && to.path !== from.query.redir){
      if(from.query.redir === 'dashboard') return next()

      return next(from.query.redir)
    }

    if(!isAuthenticated && to.meta.requiresAuth){
      const path = to.path;
      const query = path !== '/logout' ? { redir: path } : {};
      return next({ name: 'Login', query });
    }

    if (to.meta.history) Store.dispatch('services/api/router/history/add', { from, to, next });

    const isLoginPath = to.path === '/login' || to.path === '/legacy-login';

    if(isAuthenticated && isLoginPath){
      return next({name: 'dashboard'})
    }

    if(isAuthenticated && to.query.redir && from.fullPath !== to.query.redir){
      return next(to.query.redir);
    }

    return next();
  } catch (error) {
    console.error(error);
  }
})

export default router
