export default {
  get: function() {
    return {
      notifications:{
        list: [],
        pages:[], //Keeps track of already fetched pages
        paginator:{
          currentPage: null,
          totalPages: null,
          totalResults: null,
          amountPerPage: null,
          hasToPaginate: null
        },
        fetching: null,
        success: null,
        error: null,
      },
      unread:{
        fetching: null,
        success: null,
        error: null,
        count: null
      },
      remove:{
        sending: null,
        success: null,
        error: null
      },
      read:{
        sending: null,
        success: null,
        error: null
      }
    }
  }
}
