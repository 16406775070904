import Api from '@/services/api/api/index.js'
import endPoints from './endpoint'

const fetch = (args) => {
  let params = new URLSearchParams();

  params.append('course_code', args.course);
  params.append('user', args.user);

  if(null !== args.year){
    params.append('year', args.year);
  }

  return Api.get(endPoints.get('get')+'?'+params.toString());
}

export default {
  fetch,
};
