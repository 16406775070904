import teachersStore from './teachers'
import calendarsStore from './calendars'

export default {
  namespaced:true,
  modules:{
    teachers: teachersStore,
    calendars: calendarsStore
  }
}
