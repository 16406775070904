import calendarStore from './calendar'
import statsStore from './stats'

export default {
  namespaced:true,
  modules:{
    calendar: calendarStore,
    stats: statsStore
  }
}
