const fetching = state => state.notifications.fetching;
const list = state => state.notifications.list;
const fetchSuccess = state => state.notifications.success;
const fetchError = state => state.notifications.error;
const paginator = state => state.notifications.paginator;

const removeSending = state => state.remove.sending;
const removeSuccess = state => state.remove.success;
const removeError = state => state.remove.error;

const readSending = state => state.read.sending;
const readSuccess = state => state.read.success;
const readError = state => state.read.error;

const unreadCount = state => {
  return state.notifications.list.filter(notification => {
    return notification.readAt === null;
  }).length;
}

export default {
  fetching,
  list,
  paginator,
  fetchSuccess,
  fetchError,
  unreadCount,
  removeSending,
  removeSuccess,
  removeError,
  readSending,
  readSuccess,
  readError
}
