import initialState from './state'

const FETCH_UPDATED = (state, data) => {
  let notifications = data.notifications;

  for(let i = 0; i < notifications.length; i++){
    let n1 = notifications[i],
      found = false;

    for(let x = 0; x < state.notifications.list.length; x++){
      let n2 = state.notifications.list[x];
      if(n1.id === n2.id){
        found = true;
        break;
      }
    }

    if(false === found){
      state.notifications.list.push(n1);
    }
  }

  state.notifications.paginator = data.paginator;
};

const FETCHING = (state, value) => {
  state.notifications.fetching = !!value;
}

const FETCH_SUCCESS = (state, value) => {
  state.notifications.success = !!value;
}

const FETCH_ERROR = (state, value) => {
  state.notifications.error = !!value;
}

const REMOVE_SENDING = (state, value) => {
  state.remove.sending = !!value;
}

const REMOVE_SUCCESS = (state, notification) => {
  let notifications = state.notifications.list;

  if(false === notification){
    state.remove.success = false;
    return;
  }

  state.notifications.list = notifications.filter(n => {
    if(n.id !== notification.id){
      return n;
    }

    if(null === n.readAt && state.unread.count > 0){
      state.unread.count-=1;
    }
  });

  state.remove.success = true;
}

const REMOVE_ERROR = (state, value) => {
  state.remove.error = !!value;
}

const READ_SENDING = (state, value) => {
  state.read.sending = !!value;
}

const READ_SUCCESS = (state, notification) => {
  let notifications = state.notifications.list;

  if(false === notification){
    return state.read.success = false;
  }

  notifications.map(item => {
    if(item.id === notification.id){
      item.readAt = new Date(); //now ? :P
    }
  })

  if(state.unread.count > 0){
    state.unread.count-=1;
  }

  state.read.success = true;
}

const READ_ERROR = (state, value) => {
  state.read.error = !!value;
}

export default {
  FETCHING,
  FETCH_SUCCESS,
  FETCH_UPDATED,
  FETCH_ERROR,
  REMOVE_SENDING,
  REMOVE_SUCCESS,
  REMOVE_ERROR,
  READ_SENDING,
  READ_SUCCESS,
  READ_ERROR
};
