const fetching = state => state.fetching;

/**
 * Contains all calendars
 * @param state
 * @returns {*}
 */
const list = state => state.list;

/**
 * Contains course data
 * @param state
 *
 * @returns {(function(*))|default.props.course|{type, required}|default.props.course|{type}|*}
 */
const course = state => state.course;

/**
 * Contains the current calendar
 * @param state
 * @returns {*}
 */
const current = state => state.current;

export default {
  fetching,
  course,
  list,
  current,
}
