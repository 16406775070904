import api from '../api'

const fetch = ({commit, dispatch}, args, delay=500) => {
  commit('RESET_STATE');

  commit('FETCHING', true);

  return api.fetch(args)
    .then(response => {
      commit('SET_LIST_DATA', response.data);
      commit('FETCH_SUCCESS', true);
      commit('FETCHING', false);

      return response;
    })
    .catch(error => {
      commit('FETCH_SUCCESS', false);
      commit('FETCH_ERROR', error);
      commit('FETCHING', false);

      return error;
    })

};

export default {
  fetch
}
