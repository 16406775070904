import api from '../api'

const fetch = ({commit}, args) => {

  commit('RESET_STATE');
  commit('FETCHING', true);

  return api.fetch(args)
    .then(response => {
      commit('FETCH_UPDATED', response.data);
      commit('FETCHING',  false);

      return response;
    })
    .catch(e =>{
      commit('FETCHING',  false);
      commit('ERROR', e);

      return e;
    })

}

const reset = ({commit}, value) => {
  commit('RESET_STATE');
}

export default {
  fetch,
  reset
}
