const list = state => state.values;
const fetching = state => state.fetching;
const error = state => state.error;
const success = state => state.success;

export default {
  list,
  fetching,
  error,
  success
};
