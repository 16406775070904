export default {
  get: function(){
    return {
      fetching: null,
      course: null,
      list:[], //All calendars
      current: null,
      error: null
    }
  }
}
