import detailStore from './detail'
import generalStore from './general'

export default {
  namespaced:true,
  modules:{
    general: generalStore,
    detail: detailStore
  }
}
