import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'animate.css'
import './assets/style/app.scss'

import vuetify from './plugins/vuetify'
import './plugins/axios'

import AlumniBanner from './plugins/alumni/banner'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import FlagIcon from 'vue-flag-icon'
import Progress from 'vue-multiple-progress'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true
  }
})

Vue.use(FlagIcon);
Vue.use(require('vue-shortkey'))
Vue.use(Progress)
console.log(AlumniBanner, 'color:#1976d2; background-color:#242424')

Vue.component('VuePerfectScrollbar', VuePerfectScrollbar)

import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'

Vue.config.productionTip = false
Vue.prototype.$courseIconUrl = 'https://static.educacionit.com/alumni/assets/course/generic.svg';

import {initializeApp} from './store/modules/Main';

(async () => {
  await initializeApp('es');
  document.fonts.ready.then(() => {
    return new Vue({
      vuetify,
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  });
})();
