import initialState from './state.js'

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.api.get.fetching = state.api.get.fetching;
  Object.assign(state, iState);
}

const FETCHING = (state, value) => {
  state.api.get.fetching = !!value;
}

const FETCH_UPDATED = (state, data) => {
  let course = data;

  state.api.get.values = course;
  state.id = course.id;
  state.name = course.name;
  state.description = course.description;
  state.icon = course.icon;
  state.uniqid = course.uniqid;
  state.code = course.code;
  state.stats.average = course.stats.average;
  state.classesAmount = course.classesAmount;

};

export default {
  FETCHING,
  RESET_STATE,
  FETCH_UPDATED,
};
