import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const authenticate = (args) => {
  let params = new URLSearchParams();

  params.append('username', args.username)
  params.append('password', args.password)
  params.append('captcha', args.captcha)

  return Api.post(endpoints.get('login'), params,{
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

const isAuthenticated = () => {
  return Api.get(endpoints.get('authenticated'));
}

const refresh = () => {
  return Api.get(endpoints.get('refresh'));
}

const logout = () => {
  return Api.get(endpoints.get('logout'));
}

const recoverPassword = data => {
  let params = new URLSearchParams();

  params.append('username', data.username);
  params.append('captcha', data.captcha);

  return Api.post(endpoints.get('recoverPassword'), params, {
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

const changePassword = data => {
  let params = new URLSearchParams();

  params.append('hash', data.hash)
  params.append('password', data.password)
  params.append('captcha', data.captcha)

  return Api.post(endpoints.get('changePassword'), params, {
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

const validateHash = data => {
  let params = new URLSearchParams();

  params.append('hash', data.hash)

  return Api.post(endpoints.get('validateHash'), params, {
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

}


export default {
  authenticate,
  isAuthenticated,
  refresh,
  logout,
  recoverPassword,
  validateHash,
  changePassword
};
