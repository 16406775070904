import api from '../api'

const fetch = ({commit, state}) => {
  let paginator = state.notifications.paginator,
    nextPage = paginator.currentPage + 1;

  if(paginator.totalPages !== null && nextPage > paginator.totalPages){
    return;
  }

  /**
   * If we don't have to paginate, then ignore calls to this action and simply return.
   */
  if(false === paginator.hasToPaginate){
    return;
  }

  /**
   * If the current page has already been fetched, do not fetch the same notifications page twice.
   */
  if(state.notifications.list.length > 0 && state.notifications.pages.indexOf(nextPage) > -1){
    return;
  }

  /**
   * If there are no notifications loaded, we reset the pages This can happen when a user deletes some notifications.
   */
  if(0 === state.notifications.list.length){
    state.notifications.pages = [];
  }

  //Replace for mutation

  /**
   * Keep track of already fetched pages, the fetch is completely avoided
   * if the page has already been fetched. This is due to possible performance issues, we want to avoid any extra calls
   * against the API for fetching notifications.
   */
  state.notifications.pages.push(nextPage);

  commit('FETCHING', true);

  return api.fetch(nextPage)
    .then(response => {
      commit('FETCHING', false);

      commit('FETCH_UPDATED', response.data);

      commit('FETCH_SUCCESS', true);
      commit('FETCH_ERROR', false);
      commit('FETCHING', false);

      return response;
    })
    .catch(error => {
      return error;
      commit('FETCH_ERROR', true);
      commit('FETCH_SUCCESS', false);
      commit('FETCHING', false);

      return error;
    });
}

const read = ({commit}, notification) => {
  /**
   * If the notification was already read, do not mark it again as read
   */
  if(null !== notification.readAt){
    return;
  }

  commit('READ_SENDING', true);

  return api.read(notification)
    .then(response => {
      commit('READ_SENDING', false);
      commit('READ_SUCCESS', notification);
      commit('READ_ERROR', false);
    })
    .catch(error => {
      commit('READ_ERROR', true);
      commit('READ_SENDING', false);
      commit('READ_SUCCESS', false);
    })
}

const remove = ({commit, state, dispatch}, notification) => {
  let paginator = state.notifications.paginator;
  /**
   * Once there are no more notifications, fetch once more, to check if there are more notifications ???
   * do something smart to handle shit like this ....
   * THE SIMPLEST SOLUTION, would be to refetch the first page but that wouldn't be ... professional.
   */
  commit('REMOVE_SENDING', true);

  return api.remove(notification)
    .then(response => {
      commit('REMOVE_SENDING', false);
      commit('REMOVE_SUCCESS', notification);
      commit('REMOVE_ERROR', false);

      if(paginator.currentPage !== paginator.totalPages) {
        state.notifications.paginator.currentPage = null;
        state.notifications.pages = [];

        dispatch('fetch');
      }

      return response.data;
    })
    .catch(error => {
      commit('REMOVE_SENDING', false);
      commit('REMOVE_SUCCESS', false);
      commit('REMOVE_ERROR', true);

      return error;
    })
}

export default {
  fetch,
  remove,
  read
}
