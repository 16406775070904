export default {
    primary: '#0060e5',
    secondary: '#607d8b',
    accent: '#3f51b5',
    error: '#f44336',
    warning: '#e91e63',
    info: '#009688',
    success: '#03a9f4'
}

