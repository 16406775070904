

/**
 * @deprecated
 * @param state
 * @returns {*}
 */
const get = state => state.api.get.values;

const fetching = state => state.api.get.fetching;
const id = state => state.id;
const name = state => state.name;
const description = state => state.description;
const uniqid = state => state.uniqid;
const classesAmount = state => state.classesAmount;
const icon = state => state.icon;
const code = state => state.code;
const average = state => state.stats.average;

export default {
  get,
  fetching,
  id,
  name,
  description,
  uniqid,
  classesAmount,
  icon,
  code,
  average
}
