import initialState from './state'
import cacheStorage from '../../cache'

const NOT_AUTHENTICATED = (state) => {
  state.authenticated = false;
  state.pending = false;
  state.data = {};
}

const LOGIN_PENDING = (state, value) => {
  state.pending = value;
}

const LOGIN_SUCCESS = (state, credentials) => {
  state.pending = false;
  state.authenticated = true;
  cacheStorage.saveItem('application/token', credentials.token);
}

const LOGIN_FAILURE = (state, error) => {
  state.pending = false;
  state.authenticated = false;
  state.data = {};
}

const LOGOUT_SUCCESS = (state) => {
  state.authenticated = false;
  state.data = {};
  state.pending = false;
  state.session = null;
  state.logout.success = true;
  cacheStorage.saveItem('application/token', state.token);
}

const LOGOUT_ERROR = (state, data) => {
  state.logout.error = data;
}

const SESSION_SUCCESS = (state, credentials) => {
  state.data = credentials.data.data;
  state.authenticated = true;
  state.pending = false;

  /**
   * If there is no avatar or the avatar needs to be refreshed (due to a profile update)
   * then refresh the avatar
   */
  if(null === state.avatar || (typeof credentials.refreshAvatar !=='undefined' && credentials.refreshAvatar)) {
    state.avatar = state.data.avatar;

  }

  state.session = credentials.data.session;
}

const SESSION_FAILURE = (state, error) => {
  let iState = initialState.get();
  Object.assign(state, iState);
}
/**
 * Password recovery
 */

const RECOVER_PASSWORD_SENDING = (state, value) => {
  state.recover.sending = !!value;
}

const RECOVER_PASSWORD_SUCCESS = (state, value) => {
  state.recover.success = !!value;
}

const RECOVER_PASSWORD_ERRORS = (state, value) => {
  state.recover.errors = value;
}

/**
 * Password change
 */

const CHANGE_PASSWORD_SENDING = (state, value) => {
  state.changePassword.sending = !!value;
}

const CHANGE_PASSWORD_SUCCESS = (state, value) => {
  state.changePassword.success = !!value;
}

const CHANGE_PASSWORD_ERRORS = (state, value) => {
  state.changePassword.errors = value;
}

/**
 * Hash validation
 */
const HASH_VALIDATE_SENDING = (state, value) => {
  state.hashValidate.sending = !!value;
}

const HASH_VALIDATE_SUCCESS = (state, value) => {
  state.hashValidate.success = !!value;
}

const HASH_VALIDATE_ERRORS = (state, value) => {
  state.hashValidate.errors = value;
}


export default {
  NOT_AUTHENTICATED,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  SESSION_SUCCESS,
  SESSION_FAILURE,
  RECOVER_PASSWORD_SENDING,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_ERRORS,
  HASH_VALIDATE_SENDING,
  HASH_VALIDATE_SUCCESS,
  HASH_VALIDATE_ERRORS,
  CHANGE_PASSWORD_SENDING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERRORS,
}
