/**
 * @todo When connecting the real backend, just change this file to the correct
 * end points.
 */

const ENDPOINT = new Map();

ENDPOINT.set('update', '/teacher/bootcamp/student/update');

export default ENDPOINT
