import Api from '@/services/api/api/index.js'
import endpoints from './endpoint'

const update = args => {
  let params = new URLSearchParams();
  params.append('user', args.user);
  params.append('course_code', args.course);
  params.append('calendar', args.calendar);
  params.append('tasks', args.tasks);

  return Api.post(endpoints.get('update'), params,{
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export default {
  update
};
