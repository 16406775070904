import api from '../api'
import fetchDelayed from '@/store/helper/fetchDelayed'

const fetch = (context, args, delay=500) => {
  let translations = context.rootGetters['services/api/translation/getTranslations'];
  let message = translations.loaders.course;

  return fetchDelayed.fetch(
    api,
    context,
    message,
    args,
    delay
  );
}

const setCourse= ({commit}, data) => {
  commit('FETCHING', true);
  return new Promise((resolve, reject) => {
    commit('FETCH_UPDATED', data);
    commit('FETCHING', false);
    return resolve(data);
  });
}


export default {
  fetch,
  setCourse
}
