export default {
  get: function(){
    return {
      id: null,
      name: null,
      description: null,
      icon: null,
      uniqid: null,
      code: null,
      stats:{
        average: null,
      },
      classesAmount: null,
      api:{
        get:{
          fetching: false,
          error: false,
          values: {},
        },
        put: {

        },
        post:{

        },
        delete:{

        }
      }
    }
  }
}
