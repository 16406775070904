import initialState from './state.js'

const FETCHING = (state, value) => {
  state.fetching = !!value;
}

const FETCH_SUCCESS = (state, value) => {
  state.success = !!value;
}

const FETCH_ERROR = (state, error) => {
  state.error = error;
}

const SET_LIST_DATA = (state, data) => {
  state.values = data;
};

const RESET_STATE = (state) => {
  let iState = initialState.get();
  //Preserve fetching state
  iState.fetching = state.fetching;
  Object.assign(state, iState);
};

export default {
  FETCHING,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SET_LIST_DATA,
  RESET_STATE,
};
