import api from '../api'

const fetch = ({commit}, args) => {
  commit('RESET_STATE');
  commit('FETCHING', true);

  return api.fetch(args)
    .then(response => {
      commit('FETCH_UPDATED', {
        data:response.data,
        args: args
      });

      commit('FETCHING', false);

      return response;
    })
    .catch(e => {
      commit('ERROR', e);
      commit('FETCHING', false);

      return e;
    })
}

const setCalendars= ({commit}, data) => {
  return new Promise((resolve, reject) => {
      commit('FETCH_UPDATED', data);
      return resolve(data);
  });
}

const reset = ({commit}, data) => {
  commit('RESET_STATE');
}

const change = ({ commit }, calendarId) => {
  return new Promise((resolve, reject) => {
    commit('SET_CURRENT_CALENDAR', calendarId);
    return resolve(calendarId);
  })
}

export default {
  fetch,
  setCalendars,
  reset,
  change,
}
